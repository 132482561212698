<template>
  <div id="sidebar-component">
    <div class="sidebar-wrapper">

      <div class="mt-4 text-center">
        <router-link v-if="this.$store.getters.userIsAdmin" class="w-75 navbar-brand" :to="{ name: 'dashboard' }">
          <img class="w-100" :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo-white.svg')" alt="Logo">
        </router-link>
        <router-link v-else-if="this.$store.getters.userIsMarketing" class="w-75 navbar-brand" :to="{ name: 'export-marketing-index' }">
          <img class="w-100" :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo">
        </router-link>
        <router-link v-else class="w-75 navbar-brand" :to="{ name: 'sale', params: {id: this.$store.getters.getCurrentUser.shop.id }}">
          <img class="w-100" :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo-white.svg')" alt="Logo">
        </router-link>
      </div>

      <!-- Sidebar -->
      <nav class="" v-if="this.$store.getters.userIsAdmin || this.$store.getters.userIsMarketing" id="sidebar-content">
        <ul class="nav flex-column">
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Accueil</li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name.includes('dashboard')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="columns-gap" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'dashboard' }">Tableau de bord</router-link>
            </div>
          </li>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Gestion</li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name.includes('shop') || this.$route.name.includes('sale')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="shop" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'shop-list' }">Commerces</router-link>
            </div>
          </li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name.includes('user')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="person" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'user-list' }">Utilisateurs</router-link>
            </div>
          </li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name.includes('category')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="tag" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'category-list' }">Catégories</router-link>
            </div>
          </li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name.includes('company')) ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="people" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'company-list' }">Entreprises</router-link>
            </div>
          </li>
          <li v-if="this.$store.getters.userIsAdmin">
            <hr class="separator">
          </li>
          <li v-if="this.$store.getters.userIsAdmin" class="label-sidebar text-grey mb-2 ml-3">Export</li>
          <li v-if="this.$store.getters.userIsAdmin" class="nav-item p-2" :class="(this.$route.name === 'export-index') ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'export-index' }">Bilan mensuel</router-link>
            </div>
          </li>
          <li class="nav-item p-2" :class="(this.$route.name === 'export-marketing-index') ? 'sidebar-active' : ''" v-on:click="clickEventLink">
            <div class="d-flex ml-3 mb-2 mt-1">
              <b-icon class="mr-4 mt-1" icon="file-earmark-arrow-down" font-scale="1" variant="white"></b-icon>
              <router-link class="nav-link p-0" :to="{ name: 'export-marketing-index' }">Marketing</router-link>
            </div>
          </li>
        </ul>
      </nav>

      <div class="footer-sidebar small-text text-grey text-center">
        Socri REIM | {{ ChannelModule.channel.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    isMobile: {
      type: Boolean
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    clickEventLink() {
      if(this.isMobile)
      {
        this.showSidebar = false

        this.$emit('click-event-link', { showSidebar : this.showSidebar, isMobile : this.isMobile })
      }
    },
    handleCollapsedSidebar(windowWidth) {
      this.showSidebar = windowWidth >= 1069

      this.$emit('resize-collapsed', { showSidebar: this.showSidebar })
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  created() {
    this.windowWidth = window.innerWidth

    this.handleCollapsedSidebar(this.windowWidth)
  },
  watch: {
    windowWidth: function(windowWith) {
      this.handleCollapsedSidebar(windowWith)
    }
  },
  computed: {
    ...mapState(['ChannelModule']),
  }
}
</script>
